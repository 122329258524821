.footer {
    background: linear-gradient(to top, rgb(0 0 0 / 84%), rgb(17 33 29)),
        url("../img/herobg.png");
    background-size: cover; /* Cover the entire element with the background */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the background image */
    width: 100%;
}

.footer-wrapper {
    font-family: "Space Grotesk", sans-serif;
    .footer {
        background: linear-gradient(to top, rgb(17 33 29 / 80%), black),
            url("../img/herobg.png");
        background-size: cover; /* Cover the entire element with the background */
        background-position: center; /* Center the background image */
        background-repeat: no-repeat; /* Do not repeat the background image */
        width: 100%;
    }
}
