$SkyCity: "../img/TheLegend/SC-Still.png";
$TheLegendImgs: "../img/TheLegend/";

.the-legend-container {
    .desktop-only {
        display: none;
    }

    // testing
    color: white;
    // end of testing

    margin-top: 0;
    background: linear-gradient(rgb(17 33 29) 60vw, black);
    // background-color: rgb(17 33 29);
    // background: linear-gradient(to bottom, rgb(0 0 0 / 84%), rgb(17 33 29)),
    //     url("../img/herobg.png");
    background-size: cover; /* Cover the entire element with the background */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the background image */
    min-height: 70vh;

    .the-legend-container-wrapper {
        background: linear-gradient(
                to bottom,
                rgb(17 33 29) 5%,
                rgb(0 0 0 / 15%),
                rgb(17 33 29)
            ),
            // url("../img/TheLegend/SC-Still.png");
            // url("#{$TheLegendImgs}/SC-Still.png");
            url(#{$SkyCity});
        background-size: cover;
        height: 50vw;

        .the-legend-title-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-around;
            .the-legend-title-img {
                margin-top: -20px;
                min-width: 250px;
                width: 50vw;
            }
        }

        .episodes-contents-navigation {
            .episodes-contents-navigation-title {
                text-align: center;
            }
            .episodes-contents-item {
                padding-left: 10px;
            }
        }

        @media only screen and (min-width: 825px) {
            .desktop-only {
                display: block;
            }
        }
    }
}
