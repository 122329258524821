$border-line-color: rgb(77, 77, 77);
$border-line: 5px $border-line-color solid;
$border-line-radius: 3px;

.episode {
    *,
    *::before,
    *::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    .episode-wrapper {
        .video-wrapper {
            width: 100%;
            position: relative;
            .video-controls-container {
                position: absolute;
                z-index: 10;
                opacity: 0;

                transition: opacity 150ms ease-in-out;
                color: white;
                top: 0;
                bottom: 5px;
                left: 1px;
                right: 0;

                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .controls {
                    display: flex;
                    gap: 0.5rem;
                    padding: 0.25rem;
                    align-items: center;
                    justify-content: space-around;

                    button {
                        background: none;
                        border: none;
                        color: inherit;
                        padding: 0;
                        height: 50px;
                        width: 50px;
                        font-size: 3rem;
                        opacity: 0.85;
                        transition: opacity 150ms ease-in-out;
                    }

                    button:hover {
                        opacity: 1;
                    }
                }

                .timelaine-container {
                    width: 100%;
                    border: 1px solid red;
                    margin-bottom: 2px;
                    height: 20px;
                }
            }
            .video {
                border: 2px solid blue;
            }
        }

        .paused {
            .pause-btn {
                display: none;
            }
        }

        .video-wrapper:not(.paused) .play-btn {
            display: none;
        }

        .video-wrapper:hover .video-controls-container,
        .paused .video-controls-container,
        .video-wrapper:focus-within .video-controls-container {
            opacity: 1;
        }
    }
}

.episode-container {
    background-color: black;

    color: white;
    // height: 100vh;
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    padding: 10px 5vw;
    position: relative;
    box-sizing: border-box;

    .episode-container-wrapper {
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .episode-header {
            // testing
            // background-color: gray;
            width: 100%;

            .episode-header-wrapper {
                height: 4rem;
                border-bottom: $border-line;
                border-radius: $border-line-radius;
                height: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 1rem;

                .header-left {
                    height: 100%;
                    .header-logo-wrapper a {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        img {
                            box-sizing: border-box;
                            height: 3rem;
                            // width: 3rem;
                        }
                    }
                }
            }
        }

        .episode-content {
            padding-bottom: 3rem;
            max-width: 1000px;
            * {
                box-sizing: border-box;
                width: 100%;
            }
        }
    }
}

.episode-footer {
    position: absolute;
    bottom: 0;
    width: 90vw;
    height: 4rem;
    .episode-footer-wrapper {
        border-top: $border-line;
        border-radius: $border-line-radius;
        display: flex;
        justify-content: space-between;
    }
}
