@import "./colors.scss";

h1 {
    @media (max-width: 470px) {
        font-size: 2rem;
    }
}

.coming-soon {
    color: $primary;
}

.join-punks-title {
    // padding-top: 225px;
    // padding-top: 15rem;
}
