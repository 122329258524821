.store-header {
    // background-color: rgba(0, 0, 0, 0.75);
    background: linear-gradient(
        to bottom,
        rgba(0 0 0) 25%,
        rgba(0, 0, 0, 0.6) 60%,
        rgba(0, 0, 0, 0.3) 85%,
        rgba(0, 0, 0, 0) 100%
    );

    font-family: "Space Grotesk", sans-serif;
    font-size: 1.5rem;
    .store-header-wrapper {
        padding: 10px 25px;
        display: grid;
        grid-template-columns: 1fr 3fr;
        align-items: center;

        .store-nav-menu-wrapper {
            .store-nav-menu {
                display: flex;
                justify-content: space-around;
            }
        }
    }
}
@media only screen and (max-width: 930px) {
    .store-header {
        .store-header-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            .store-header-right {
                width: 80vw;
                .store-nav-menu {
                    justify-content: space-around;
                }
            }
        }
    }
}

@media only screen and (max-width: 715px) {
    .store-header {
        background: linear-gradient(
            to bottom,
            rgba(0 0 0) 30%,
            rgba(0, 0, 0, 0.8) 45%,
            rgba(0, 0, 0, 0.6) 70%,
            rgba(0, 0, 0, 0.3) 90%,
            rgba(0, 0, 0, 0) 100%
        );

        .store-header-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            .store-nav-menu {
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
}
